import styled from 'styled-components'
import { Container, Typography } from '@mui/material';
import { Divider } from '@mui/material';
import logo_letras from '../../assets/images/logo_letras.png';
import ContainerEco from '../components/ContainerEco'

import FunctionsCommon from '../functions/FunctionsCommon';

const Image = styled.img`
    height: 50px;
    width: 150px;
    cursor: pointer; 
    z-index: 1;
`
const DividerUI = styled(Divider)`
width: 100%;
`
export const InputUI = styled.input`
    borderRadius: 15px;
    border: 1px;
    height: 50px;
    width: 95%;
    type: search;
    arial-label: Búsqueda;
    margin: 10px;
`

export const ContainerUI = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;
`
export const Container_Inline = styled.div`
    display: inline;
`

export const H1 = styled.h1`
    width: 100%;
`
export const DivUI = styled.div`
    width: 100%;        
    max-height: 125px;
`

export const DivUIPrincipal = styled.div`
    width: 100%;
    background: transparent;
    padding-bottom: 1px;
`

const irAlInicio = () => () => {
    window.location.replace("https://turismo123.com");
};

export function HeaderUI(props) {
    return (
        <Container>
            <ContainerUI>
                <Image onClick={irAlInicio()} src={logo_letras} />
                <Typography sx={{ fontSize: { sm: 28, }, fontStyle: 'italic', fontWeight: 'bold', color: 'azure' }} variant="body1" color="text.secondary">
                    ...turismo a la carta
                </Typography>                
            </ContainerUI>
            <DividerUI />
            <ContainerEco
                localidades={props.localidades}                
                clickBuscar={props.clickBuscar}
                buscarPrestador={props.buscarPrestador}
                ecoSistemaSeleccionado={FunctionsCommon.ecoSistemaSeleccionado}
                loading={props.loading}
            />
            {/* <ContainerUI>
                <Autocomplete prestadores={props.prestadores} filter={props.filter} />
                <ButtonFind onClick={() => onClick(props.filter)} />
            </ContainerUI> */}
        </Container>
    )
}

export default ContainerUI;