import * as React from 'react';
import {
    AccordionSummary,
    Avatar,
    Container,
    Divider,
    ListItemIcon,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Radio,
    RadioGroup,
} from '@mui/material';

import { Paper as PaperMui } from '@mui/material';

import { styled } from '@mui/material/styles';

import SkeletonCard from '../components/SkeletonCard';
import CardResponsive from '../components/CardResponsive'

import Data from '../../servicio/Data';

import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import MuiAccordion from '@mui/material/Accordion';
import ArrowDropDownCircleTwoToneIcon from '@mui/icons-material/ArrowDropDownCircleTwoTone';
import NotListedLocationTwoToneIcon from '@mui/icons-material/NotListedLocationTwoTone';
import ExploreTwoToneIcon from '@mui/icons-material/ExploreTwoTone';

import { makeStyles } from '@mui/styles';

import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';

import WithoutResults from './WithoutResults';

const Item = styled(PaperMui)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Accordion = styled((props) => (
    <MuiAccordion elevation={4} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    color: 'azure',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&:hover': {
        transition: '0.1s',
        transform: 'scale(1.0001)',
        boxShadow: `0 1px 3px 0`,
        backgroundColor: '#0095DE',
        cursor: 'pointer',
        '& .css-jikzxl-MuiTypography-root': {
            fontWeight: 'bold',
            color: 'azure',
        },
        '& .css-10mhs0l-MuiSvgIcon-root': {
            color: 'azure',
        },
        '& .css-scqpv1-MuiSvgIcon-root': {
            color: 'azure',
        },
        '& .css-p30gpn': {
            color: 'azure',
        },
        '& .css-1yjl5cc': {
            color: 'azure',
        }
    },
    '& .css-10tthpj-MuiButtonBase-root-MuiListItemButton-root': {
        color: 'white',
        '&:hover': {
            color: 'azure',
            '& .css-1579q7f-MuiSvgIcon-root': {
                color: 'azure',
            }
        },
    },
}));

const Paper = styled((props) => (
    <PaperMui elevation={4} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    color: 'azure',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&:hover': {
        transition: '0.1s',
        transform: 'scale(1.0001)',
        boxShadow: `0 1px 3px 0`,
        backgroundColor: '#0095DE',
        cursor: 'pointer',
        color: 'azure',
    },
}));

const FireNav = styled(List)({
    background: 'linear-gradient(to left bottom, rgb(0, 77, 205) 20%, rgba(0, 77, 205, 0.584))',
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
    '&:hover': {
        '& .css-le9ab0-MuiAvatar-root': {
            background: 'azure'
        },
        '& .css-tn8bk4': {
            background: 'azure'
        }
    }
});

export default function AdvancedSearch(props) {

    const [paises, setPaises] = React.useState([])
    const [estados, setEstados] = React.useState([])
    
    const useStyles = makeStyles(() => ({
        radioStyle: {
            color: 'black',
            '&:hover': {
                color: 'azure',
            }
        }
    }));

    const classes = useStyles();
    React.useEffect(() => {        
        Data
            .paises()
            .then((response) => {
                let datos = [];
                datos.push({
                    codigo_pais: response.data[0].codigo_pais,
                    nombre: response.data[0].nombre
                })
                setPaises(datos);
                Data
                    .estados('ven')
                    .then((response) => {
                        //console.log('estados', response.data);
                        setEstados(response.data);
                    })
            })
    }, [])

    return (
        <Container component="section" sx={{ mt: 3, mb: 4, background: 'transparent', paddingLeft: 0 }}>
            <Stack
                direction={{ xs: 'column', sm: 'row', }}
            >
                <Grid item xs={4}
                    sx={{
                        maxWidth: {
                            xs: '100% !important',
                            sm: '23.333333% !important',
                            md: '21.333333% !important',
                            xl: '21.333333% !important',
                            lg: '21.333333% !important',
                        },
                    }}
                >
                    <FireNav id="aqui" component="nav" disablePadding
                        sx={{
                            width: '100%',
                            marginRight: '60px',
                        }}
                    >
                        <Divider />
                        <ListItemButton component="a">
                            <ListItemIcon><Avatar><TravelExploreTwoToneIcon sx={{ color: 'rgb(0, 77, 205)', }} /></Avatar></ListItemIcon>
                            <ListItemText
                                sx={{ my: 0 }}
                                primary="Pais"
                                primaryTypographyProps={{
                                    fontSize: 18,
                                    fontWeight: 'medium',
                                    letterSpacing: 0,
                                }}
                            />
                        </ListItemButton>
                        <Divider />
                        <Divider />
                    </FireNav>
                    {paises.map((pais) => {
                        const labelIdPaises = `checkbox-list-secondary-label-${pais.nombre}`;
                        return (
                            <Paper
                                sx={{
                                    background: 'transparent',
                                }}
                            >
                                <ListItem
                                    key={pais.codigo_pais}
                                    secondaryAction={
                                        <Radio
                                            edge="end"
                                            onChange={props.handleTogglePaises(pais.codigo_pais)}
                                            checked={props.checkedPaises === pais.codigo_pais}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ExploreTwoToneIcon
                                            fontSize="medium"
                                            sx={{
                                                paddingRight: 1,
                                                color: 'rgb(0, 77, 205)',
                                            }} />
                                        <ListItemText
                                            id={labelIdPaises} primary={`${pais.nombre}`}
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                lineHeight: '15px',
                                                mb: '0px',
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </Paper>

                        );
                    })}
                    <FireNav component="nav" disablePadding>
                        <Divider />
                        <ListItemButton component="a">
                            <ListItemIcon><Avatar><MapTwoToneIcon sx={{ color: 'rgb(0, 77, 205)', }} /></Avatar></ListItemIcon>
                            <ListItemText
                                sx={{ my: 0 }}
                                primary="Estados"
                                primaryTypographyProps={{
                                    fontSize: 18,
                                    fontWeight: 'medium',
                                    letterSpacing: 0,
                                }}
                            />
                        </ListItemButton>
                        <Divider />
                    </FireNav>
                    {estados.map((estado, index) => {
                        return (
                            <Accordion
                                expanded={props.expanded === estado.codigo_estado}
                                onChange={props.handleChangeEstados(estado.codigo_estado)}
                                TransitionProps={{ unmountOnExit: true }}
                                sx={{ background: 'transparent' }}
                            >
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownCircleTwoToneIcon sx={{ color: 'rgb(0, 77, 205)', }} />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <NotListedLocationTwoToneIcon
                                        fontSize="large"
                                        sx={{
                                            paddingRight: 1,
                                            color: 'rgb(0, 77, 205)',
                                        }}
                                    />
                                    <Typography sx={{ flexShrink: 0, alignSelf: 'center' }}>{estado.nombre}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RadioGroup aria-label="gender" name="row-radio-buttons-group">
                                        {props.ciudades.map((ciudad) => {
                                            const labelIdCiudades = `checkbox-list-secondary-label-${ciudad.nombre}`;
                                            return (
                                                <ListItem
                                                    key={ciudad.codigo_ciudad}
                                                    secondaryAction={
                                                        <Radio
                                                            className={classes.radioStyle}
                                                            edge="end"
                                                            onChange={props.handleToggleCiudades(ciudad.codigo_ciudad)}
                                                            checked={props.checkedCiudades === ciudad.codigo_ciudad}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ExploreTwoToneIcon
                                                            fontSize="medium"
                                                            sx={{
                                                                paddingRight: 1,
                                                                color: 'rgb(0, 77, 205)',
                                                            }} />
                                                        <ListItemText
                                                            id={labelIdCiudades} primary={`${ciudad.nombre}`}
                                                            primaryTypographyProps={{
                                                                fontSize: 14,
                                                                lineHeight: '15px',
                                                                mb: '0px',
                                                            }}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </RadioGroup>
                                </AccordionDetails>
                            </Accordion>)
                    })}
                </Grid>
                <Grid item xs={8}
                    sx={{
                        maxWidth: '100%',
                        flexBasis: '73.666667%',
                        paddingLeft: '0px',
                        paddingBottom: '31px',
                    }}
                >
                    {
                        props.prestadores.length === 0 ?
                            <WithoutResults tipo_resultado={'paginacion'} descripcion={'No hay ningún elemento para mostrar'} />
                            : <CardResponsive
                                prestadores={props.prestadores}
                                openInsertComment={props.openInsertComment}
                                clickOpenInsertComment={props.clickOpenInsertComment}
                                clickCloseInsertComment={props.clickCloseInsertComment}
                            />
                    }
                </Grid>
            </Stack>
        </Container >
    )
}