import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

import logo from "../../assets/images/turismo123.png";
import logo_letras from '../../assets/images/logo_letras.png';
import CopyrightTwoToneIcon from '@mui/icons-material/CopyrightTwoTone';

import icInstgram from '../../assets/images/ic-instagram.png'

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

const ImgIcon = styled('img')({
    margin: 0,
    height: '-webkit-fill-available',
    width: '100%',
    objectFit: 'cover',
    display: 'flex',
});

const styles = {
    footer: {
        width: '100%',
        background: '#EFFCF4',
        position: 'absolute',
        overflow: 'visible',
        top: '93%,'
    },
}

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: 'azure',
    background: 'transparent',
    boxShadow: 'none'
}));

const redirigirInstagram = (cuenta) => {
    window.open('https://www.instagram.com/' + cuenta + '/');
}

export default function Footer() {
    return (
        <footer style={styles.footer}>
            <Paper sx={{ p: 2, margin: 'auto', flexGrow: 1, background: '#102027', borderRadius: 0 }}>
                <Grid container spacing={4}>
                    <Grid item>
                        <ButtonBase sx={{ width: 128, height: 128 }}>
                            <Img alt="complex" src={logo_letras} />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            {/* <Grid item xs>
                                <Grid item sx={{ display: 'flex', alignItems: 'end', alignSelf: 'center', justifyContent: 'left' }}>
                                    <ContactMailTwoToneIcon sx={{ color: '#001E3C' }} />
                                    <Typography sx={{ cursor: 'pointer' }} variant="body1">
                                        Contactanos
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'end', alignSelf: 'center', justifyContent: 'left' }}>
                                    <InfoTwoToneIcon sx={{ color: '#001E3C' }} />
                                    <Typography sx={{ cursor: 'pointer' }} variant="body1">
                                        Acerca de nosotros
                                    </Typography>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ flexGrow: 1, }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4.5} >
                            <Item></Item>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} >
                            <Item>
                                <Grid item sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'left', paddingBottom: 1 }}>
                                    <Typography sx={{ cursor: 'pointer', color: 'white' }} variant="body2">
                                        Síguenos en:
                                    </Typography>
                                    <IconButton aria-label="instagram"
                                        size="large"
                                        sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                    >
                                        <ImgIcon
                                            alt="Acces0 Instagram Turismo 123"
                                            src={icInstgram}
                                            onClick={() => redirigirInstagram('_turismo123')}
                                            sx={{
                                                objectFit: 'fill',
                                            }}
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'left', paddingBottom: 1 }}>
                                    <Typography sx={{ cursor: 'pointer', color: 'white' }} variant="body2">
                                        Front End E&J, Soluciones Integrales, C.A.
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'left', paddingBottom: 1 }}>
                                    <Typography sx={{ cursor: 'pointer', color: 'white' }} variant="body2">
                                        RIF: J41011992-6 Copyright 2022. Todos los derechos reservados
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'end', alignSelf: 'center', justifyContent: 'left' }}>
                                    <CopyrightTwoToneIcon sx={{ color: '#001E3C', color: 'white' }} />
                                    <Typography sx={{ cursor: 'pointer', color: 'white' }} variant="body2">
                                        Powered by Front End 123. Version Beta 1.5
                                    </Typography>
                                </Grid>
                            </Item>
                        </Grid>
                        <Grid item xs={2} sm={4} md={3} >
                            <Item></Item>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </footer>
    );
}
