import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormProviderDataBasic from './FormProviderDataBasic';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ws_image from '../../assets/images/whatsapp.png'
import ig_image from '../../assets/images/facebook.png'
import fb_image from '../../assets/images/ic-instagram.png'
import page_image from '../../assets/images/page.png'
import email_image from '../../assets/images/email.png'
import { styled } from '@mui/material/styles';
import { IconButton, Container } from '@mui/material';
import TextField from '@mui/material/TextField';
import { SocialMediaProvider, TypeProvider, ServicesProvider } from './RegisterProvider';
import DataBasicProvider from './RegisterProvider';
import ProviderService from '../../servicio/ProviderService';

const Img = styled('img')({
  margin: 0,
  height: '-webkit-fill-available',
  width: '100%',
  objectFit: 'cover',
  display: 'flex',
});

const steps = [
  {
    id: 1,
    label: 'Datos basicos',
    description: `Datos basicos`,
  },
  {
    id: 2,
    label: 'Tipo de prestador de servicio',
    description:
      'Tipo de prestador de servicio',
  },
  {
    id: 3,
    label: 'Comunicaciones',
    description: `Comunicaciones`,
  },
  {
    id: 4,
    label: 'Servicios',
    description: `Servicios`,
  },
  {
    id: 5,
    label: 'Etiquetas',
    description: `Etiquetas`,
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [prestador, setPrestador] = React.useState([]);
  const [nombre, setNombre] = React.useState('');
  const [direccion, setDireccion] = React.useState();
  const [ciudad, setCiudad] = React.useState();
  const [ws, setWs] = React.useState();
  const [ig, setIg] = React.useState();
  const [fb, setFb] = React.useState();
  const [www, setWww] = React.useState();
  const [email, setEmail] = React.useState();
  const [servicio1, setServicio1] = React.useState();
  const [servicio2, setServicio2] = React.useState();
  const [servicio3, setServicio3] = React.useState();
  const [etiqueta, setEtiqueta] = React.useState();

  const [tipoprestador, setTipoPrestador] = React.useState({
    todos: true,
    hotel: true,
    restaurant: false,
    paseos: false,
    operador: false,
    buses: false,
    toldos: false,
    seguros: false
  });

  const changeHandler = (event) => {

    switch (event.target.name) {
      case 'nombre':
        setNombre(event.target.value);
        break;
      case 'direccion':
        setDireccion(event.target.value);
        break;
      case 'ciudad':
        setCiudad(event.target.value);
        break;
      case 'ws':
        setWs(event.target.value);
        break;
      case 'ig':
        setIg(event.target.value);
        break;
      case 'fb':
        setFb(event.target.value);
        break;
      case 'www':
        setWww(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'servicio1':
        setServicio1(event.target.value);
        break;
      case 'servicio2':
        setServicio2(event.target.value);
        break;
      case 'servicio3':
        setServicio3(event.target.value);
        break;
      case 'etiqueta':
        setEtiqueta(event.target.value);
        break;
    }

  }

  const changeHandlerChekbox = (event) => {
    setTipoPrestador({
      ...tipoprestador,
      [event.target.name]: event.target.checked,
    });
  }

  const limpiar = (event) => {
    setNombre('');

    setDireccion('');

    setCiudad('');

    setWs('');

    setIg('');

    setFb('');

    setWww('');

    setEmail('');

    setServicio1('');

    setServicio2('');

    setServicio3('');

    setEtiqueta('');

  }


  const handleNext = (event) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setPrestador([])
    switch (activeStep) {
      case 4:
        prestador.push({
          nombre: nombre,
          direccion: direccion,
          codigo_ciudad: ciudad,
          codigo_prestador: 0,
          clave: "",
          activo: 0,
          codigo_pais: "VEN",
          premium: 0,
          tipoprestador: tipoprestador,
          ws: ws,
          fb: fb,
          ig: ig,
          www: www,
          email, email,
          servicio1: servicio1,
          servicio2: servicio2,
          servicio3: servicio3,
          etiqueta: etiqueta
        })

        ProviderService
          .preregistro(prestador)
          .then((response) => {
            console.log(response)
            limpiar();

          },
            error => {
              console.log(error.toString())
            })


        console.log(prestador)
        break
      default:
        break
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);

  };

  return (
    <Container component="section" id="contenedor"
      sx={{
        paddingTop: 10,
        background: '#eee',
      }}
    >
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 4 ? (
                    <Typography variant="caption">Último paso</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                {step.id === 1 ?
                  <DataBasicProvider
                    nombre={nombre}
                    direccion={direccion}
                    ciudad={ciudad}
                    changeHandler={changeHandler}
                  />
                  :
                  step.id === 2 ?
                    <TypeProvider
                      tipoprestador={tipoprestador}
                      changeHandlerChekbox={changeHandlerChekbox}
                    />
                    :
                    step.id === 3 ?
                      <SocialMediaProvider
                        ws={ws}
                        fb={fb}
                        ig={ig}
                        www={www}
                        email={email}
                        changeHandler={changeHandler}
                      />
                      :
                      step.id === 4 ?
                        <ServicesProvider
                          servicio1={servicio1}
                          servicio2={servicio2}
                          servicio3={servicio3}
                          changeHandler={changeHandler} />
                        :
                        step.id === 5 ?
                          <TextField
                            required
                            autoFocus
                            id="etiqueta"
                            name="etiqueta"
                            type="text"
                            label="Etiquetas"
                            fullWidth
                            inputProps={{ maxLength: 200 }}
                            value={etiqueta}
                            onChange={changeHandler}
                          />
                          :
                          null
                }

                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button

                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Atrás
                    </Button>
                  </div>
                </Box>
              </StepContent>

            </Step>

          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <h1>Registro finalizado. {nombre}. Pronto se comunicaran con usted para confirmar el registro!</h1>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Agregar otro
            </Button>
          </Paper>
        )}
      </Box>
    </Container>
  );
}