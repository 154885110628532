import axios from "axios";
//import moment from "moment";
//import Review from '../modules/components/Review';
import * as React from 'react';
import PropTypes from 'prop-types';

class Data {

  Opciones = async () => {
    return [
      {
        id: 0,
        tipo: 'PL',
        nombre: "PLAYA",
        opciones: [
          {
            id: 0,
            idopcion: "RE",
            opcion: "Restaurantes"
          },
          {
            id: 0,
            idopcion: "PO",
            opcion: "Posadas"
          },
          {
            id: 0,
            idopcion: "LA",
            opcion: "Lanchas"
          },
          {
            id: 0,
            idopcion: "TO",
            opcion: "Toldos"
          }
        ]
      },
      {
        id: 1,
        tipo: 'CI',
        nombre: "CIUDAD",
        opciones: [
          {
            id: 1,
            idopcion: "RE",
            opcion: "Restaurantes"
          },
          {
            id: 1,
            idopcion: "PO",
            opcion: "Hoteles"
          },
          {
            id: 1,
            idopcion: "PA",
            opcion: "Paseos"
          },
        ]
      },
      {
        id: 2,
        tipo: 'MN',
        nombre: "MONTAÑA",
        opciones: [
          {
            id: 2,
            idopcion: "PO",
            opcion: "Posadas"
          },
          {
            id: 2,
            idopcion: "PA",
            opcion: "Paseos"
          }
        ]
      },
      {
        id: 3,
        tipo: 'LL',
        nombre: "LLANOS",
        opciones: [
          {
            id: 3,
            idopcion: "RE",
            opcion: "Restaurantes"
          },
          {
            id: 3,
            idopcion: "PO",
            opcion: "Posadas"
          },
          {
            id: 3,
            idopcion: "PA",
            opcion: "Paseos"
          }
        ]
      }
    ];
  };

  PrestadoresJSON = async () => {
    return [
      {
        id: 0,
        avatar: 'KC',
        nombre: 'Kiosco "Los Caneyes"',
        ubicacion: 'Bahia de Patanemo',
        descripcion: 'La Bahía de Patanemo es una playa del Estado Carabobo en Venezuela. Se encuentra ubicada en el Muni.',
        ws: [
          {
            ws: '584143409838',
          },
          {
            ws: '584145807408',
          }
        ],
        fb: '',
        ig: '',
        imagenes: [
          {
            etiqueta: 'En la bahía de Patanemo',
            img: 'ptocabello-1.jpg',
            principal: 1,
          },
          {
            etiqueta: 'Los mejores almuerzos',
            img: 'ptocabello-2.jpeg',
            principal: 0,
          },
          {
            etiqueta: 'Variedades del mar',
            img: 'ptocabello-3.jpeg',
            principal: 0,
          },
          {
            etiqueta: 'Y mucho más',
            img: 'ptocabello-4.jpeg',
            principal: 0,
          }
        ]
      },
      {
        id: 1,
        avatar: 'S2',
        nombre: 'Servicio 2',
        ubicacion: 'Choroní, Aaragua',
        descripcion: 'Breve descripción del prestador de servicios en Choroní',
        ws: [
          {
            ws: '584143409838',
          },
          {
            ws: '584145807408',
          },
          {
            ws: '5841458074',
          },
        ],
        fb: '',
        ig: '',
        imagenes: [
          {
            etiqueta: 'En pleno Malecón de Choroni',
            img: 'choroni-1.jpg',
            principal: 1,
          },
          {
            etiqueta: 'Comidas y bebidas',
            img: 'choroni-2.jpg',
            principal: 0,
          },
          {
            etiqueta: 'Comidas',
            img: 'choroni-3.jpg',
            principal: 0,
          }
        ]
      },
      {
        id: 2,
        avatar: 'S3',
        nombre: 'Servicio 3',
        ubicacion: 'Margarita, Porlamar',
        descripcion: 'Breve descripción del prestador de servicios en Margarita',
        ws: [
          {
            ws: '584143409838',
          },
          {
            ws: '584145807408',
          }
        ],
        fb: '',
        ig: '',
        imagenes: [
          {
            etiqueta: 'En Porlamar',
            img: 'margarita-1.jpg',
            principal: 1,

          },
          {
            etiqueta: 'Todo tipo de comidas',
            img: 'margarita-2.jpg',
            principal: 0,
          },
          {
            etiqueta: 'Y mucho más',
            img: 'margarita-3.jpg',
            principal: 0,
          }
        ]
      }
    ];
  };

  servicios = async () => {

    const config = {
      headers: { "Authorization": `Bearer ` }
    };

    return axios.get(`${this.obtenerURL_API()}` + "/api/servicios/")
  }

  prestadores = async () => {

    const config = {
      headers: { "Authorization": `Bearer ` }
    };

    return axios.get(`${this.obtenerURL_API()}` + "/api/prestadores/premium")
  }

  busquedaPrestadores = async (ecosistema, opcioneco, busqueda) => {
    
    const config = {
      headers: { "Authorization": `Bearer` }
    };

    let body = {
      ecosistema: ecosistema,
      opcioneco: opcioneco,
      busqueda: busqueda
    }

    return axios.get(`${this.obtenerURL_API()}` + "/api/prestadores/", { params: body })
  }

  prestadoresPremium = async () => {

    const config = {
      headers: { "Authorization": `Bearer ` }
    };

    return axios.get(`${this.obtenerURL_API()}` + "/api/prestadores/premium/")
  }

  prestadorCodigo = async (solicitud) => {

    const config = {
      headers: { "Authorization": `Bearer ` }
    };

    return axios.get(`${this.obtenerURL_API()}` + "/api/prestadores/" + `${solicitud}`)
  }

  localidades = async () => {

    const config = {
      headers: { "Authorization": `Bearer ` }
    };

    return axios.get(`${this.obtenerURL_API()}` + "/api/localidades/")
  }

  estados = async (codigo) => {
    return axios.get(`${this.obtenerURL_API()}` + "/api/estados/consultarporutilizado/" + `${codigo}`)
  }

  paises = async () => {    
    return axios.get(`${this.obtenerURL_API()}` + "/api/paises/consultarporutilizado/");
  }

  ciudadesPorCodigoEstado = async (codigo) => {
    return axios.get(`${this.obtenerURL_API()}` + "/api/ciudades/consultarporutilizado/" + `${codigo}`);
  }

  consultaAmpliada = async (data) => {
    
    return axios.get(`${this.obtenerURL_API()}` + "/api/prestadores/consultarampliada/",
      {
        params: {
          codigo_pais: data.codigo_pais,
          codigo_estado: data.codigo_estado,
          codigo_ciudad: data.codigo_ciudad,
          busqueda: data.busqueda,
          codigo_servicio:  data.codigo_servicio
        }
      })
  }

  obtenerURL_Principal() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
    let url_principal = window.location.host;
    //console.log(url_principal);
    let partes = url_principal.split('.');
    switch (parseInt(partes.length)) {
      case 1: return 'http://' + url_principal.replace(/3000/g, '4000'); //ENTORNO DE DESARROLLO CON PUERTO 4000
      case 2: return 'https://' + partes[0] + '.' + partes[1]; //DOMINIO
      case 3:
        if (partes[0] !== 'www') {
          //console.log(url_principal.replace(/app/g, 'api'));
          let url_api = partes[0].replace(/app/g, 'api');
          return 'https://' + url_api + '.' + partes[1] + '.' + partes[2];  //SI API ESTA EN SUBDOMINIO
        } else {
          return 'https://' + partes[1] + '.' + partes[2]; //SUBDOMINIO
        }
    }
  }

  obtenerURL_Imagenes() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
    let url_principal = window.location.host;
    //console.log(url_principal);
    if(url_principal.toString().includes('localhost:3000')){
      return process.env.REACT_APP_URL_HOST_DEV
    }else{
      return process.env.REACT_APP_URL_HOST
    }
  }

  obtenerURL_API() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
    let url_principal = window.location.host;
    //console.log(url_principal);
    if(url_principal.toString().includes('localhost:3000')){
      return process.env.REACT_APP_URL_API_DEV
    }else{
      return process.env.REACT_APP_URL_API
    }
  }

}


export default new Data();