import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';


import { styled } from '@mui/system';
import { makeStyles, useTheme } from '@mui/styles';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import useMediaQuery from '@mui/material/useMediaQuery';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';

import DirectionsBoatTwoToneIcon from '@mui/icons-material/DirectionsBoatTwoTone';
import FlatwareTwoToneIcon from '@mui/icons-material/FlatwareTwoTone';
import FollowTheSignsTwoToneIcon from '@mui/icons-material/FollowTheSignsTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';

import { Badge, Chip } from '@mui/material';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';

import DeckTwoToneIcon from '@mui/icons-material/DeckTwoTone';
import CommuteTwoToneIcon from '@mui/icons-material/CommuteTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import HikingTwoToneIcon from '@mui/icons-material/HikingTwoTone';
import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import DiningTwoToneIcon from '@mui/icons-material/DiningTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import VpnLockTwoToneIcon from '@mui/icons-material/VpnLockTwoTone';

import Data from '../../servicio/Data';



const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const Tab1 = styled(MuiTab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  max-width: 175px;
  padding: 0px 0px;  
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    background-color: ${blue[400]};        
  }

  &.${buttonUnstyledClasses.focusVisible} {
    color: #fff;
    outline: none;
    background-color: ${blue[200]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
    & .css-6od3lo-MuiChip-label {
      background-color: ${blue[50]};
      color: ${blue[600]};
    }
    & .css-8jbgyf-MuiBadge-badge {
      border: 1px solid ${blue[600]};
      color: white;
    }
    & .css-1aft5wk {
      color: ${blue[600]};
    }
    & .css-9iedg7 {
      color: ${blue[600]};
    }
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px; 
  max-Height: 60px; 
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const AntTabs = styled(Tabs)({
  backgroundColor: '#eee',
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  '& .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root': {
    marginBottom: '6px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: `${blue[400]}`
    },
  },
  '& .css-1q0ig8p': {
    marginBottom: '6px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: `${blue[400]}`
    },
  },
  '&:hover': {
    '& .css-ptiqhd-MuiSvgIcon-root': {
      color: 'azure',
    },
    '& .css-1k33q06': {
      color: 'azure',
    },
  }
});

const AntTab = styled((props) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const obtenerIconTab = (codigo, contador) => {
  switch (codigo) {
    case 0:
      return <BallotTwoToneIcon />
    case 1:
      return <HotelTwoToneIcon />
    case 2:
      return <DiningTwoToneIcon />
    case 3:
      return <HikingTwoToneIcon />
    case 4:
      return <LuggageTwoToneIcon />
    case 5:
      return <CommuteTwoToneIcon />
    case 6:
      return <DeckTwoToneIcon />
    case 7:
      return <VpnLockTwoToneIcon />
  }
}

export default function IconTabs(props) {

  const [servicios, setServicios] = React.useState([])
  const [codigo_servicio, setCodigoServicio] = React.useState(1);

  const useStyles = makeStyles((theme) => ({
    tabStyle: {
      minHeight: '31px !important',
      marginTop: 0,
    },
    badgeStyle: {
      '& .css-8jbgyf-MuiBadge-badge': {
        right: 5,
      }
    },
  }));

  const classes = useStyles();

  const obtenerLabelTab = (label, contador) => {
    return <Badge badgeContent={contador} color="error" max={100} showZero className={classes.badgeStyle}>
      <Chip
        sx={{
          marginTop: '-5px',
          marginBottom: '-5px',
          background: 'transparent',
          color: 'white',
          textTransform: 'none',
          fontSize: 11
        }}
        label={label} />
    </Badge>
  }

  const obtenerMinHeight = () => {
    if (m320) {
      return '60px'
    } else if (m375) {
      return '80px'
    } else if (m425) {
      return '100px'
    }
  }

  React.useEffect(() => {

    Data
      .servicios()
      .then(
        (response) => {
          setServicios(response.data);
          setCodigoServicio(response.data[0].codigo_servicio)
        },
        error => {
        }
      );
  }, [])

  const m320 = useMediaQuery('(max-width:320px)');
  const m375 = useMediaQuery('(max-width:375px)');
  const m425 = useMediaQuery('(max-width:425px)');
  const m768 = useMediaQuery('(max-width:768px)');
  const m1024 = useMediaQuery('(max-width:1024px)');
  const m1440 = useMediaQuery('(max-width:1440px)');

  return (
    <AntTabs
      variant="scrollable"
      scrollButtons={true}
      allowScrollButtonsMobile
      style={{
        boxShadow: `0 3px 6px 0 `,
        borderRadius: 10,
        backgroundColor: `${blue[500]}`,
        minHeight: '60px',
        paddingTop: 4,
        border: '1px rgb(0, 127, 255)',
      }}
      value={props.codigo_servicio}
      onChange={props.handleChangeTab}
    >

      {servicios.map((servicio) => (

        <Tab1
          className={classes.tabStyle}
          label={servicio.nombre}
          value={servicio.codigo_servicio}
          icon={obtenerIconTab(servicio.codigo_servicio, props.contador_hoteles)}
          label=
          {
            servicio.codigo_servicio === 0
              ?
              obtenerLabelTab(servicio.nombre, props.contador_todos)
              :
              servicio.codigo_servicio === 1
                ?
                obtenerLabelTab(servicio.nombre, props.contador_hoteles)
                :
                servicio.codigo_servicio === 2
                  ?
                  obtenerLabelTab(servicio.nombre, props.contador_restaurantes)
                  :
                  servicio.codigo_servicio === 3
                    ?
                    obtenerLabelTab(servicio.nombre, props.contador_paseos)
                    :
                    servicio.codigo_servicio === 4
                      ?
                      obtenerLabelTab(servicio.nombre, props.contador_operadores)
                      :
                      servicio.codigo_servicio === 5
                        ?
                        obtenerLabelTab(servicio.nombre, props.contador_embarcaciones)
                        :
                        servicio.codigo_servicio === 6
                          ?
                          obtenerLabelTab(servicio.nombre, props.contador_toldos)
                          :
                          servicio.codigo_servicio === 7
                            ?
                            obtenerLabelTab(servicio.nombre, props.contador_seguros)
                            :
                            obtenerLabelTab(servicio.nombre, 0)                          

          }
      disableRipple
      sx={{
        marginBottom: '-11px',
        marginTop: '-11px',
        whiteSpace: 'nowrap',
      }}
        />

      ))}

    </AntTabs>

  );
}