import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ws_image from '../../assets/images/whatsapp.png'
import ig_image from '../../assets/images/facebook.png'
import fb_image from '../../assets/images/ic-instagram.png'
import page_image from '../../assets/images/page.png'
import email_image from '../../assets/images/email.png'
import { styled } from '@mui/material/styles';
import { IconButton, Box } from '@mui/material';
import TextField from '@mui/material/TextField';

const Img = styled('img')({
  margin: 0,
  height: '-webkit-fill-available',
  width: '100%',
  objectFit: 'cover',
  display: 'flex',
});

const steps = [
  {
    id: 1,
    label: 'Datos basicos',
    description: `Datos basicos`,
  },
  {
    id: 2,
    label: 'Tipo de prestador de servicio',
    description:
      'Tipo de prestador de servicio',
  },
  {
    id: 3,
    label: 'Comunicaciones',
    description: `Comunicaciones`,
  },
  {
    id: 4,
    label: 'Servicios',
    description: `Servicios`,
  },
  {
    id: 5,
    label: 'Etiquetas',
    description: `Etiquetas`,
  },
];

export function DataBasicProvider(props) {
  const { changeHandler } = props
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        required
        hiddenLabel
        id="nombre"
        name="nombre"
        label="Nombre"
        type="text"
        fullWidth
        inputProps={{ maxLength: 50 }}
        value={props.nombre}
        onChange={changeHandler}
      />
      <TextField
        required
        autoFocus
        value={props.direccion}
        id="direccion"
        name="direccion"
        label="Direccion"
        type="text"
        fullWidth
        inputProps={{ maxLength: 100 }}
        onChange={changeHandler}
      />
      <TextField
        required
        autoFocus
        value={props.ciudad}
        id="ciudad"
        name="ciudad"
        label="Ciudad"
        type="text"
        fullWidth
        inputProps={{ maxLength: 50 }}
        onChange={changeHandler}
      />
    </Box>
  )
}
export function SocialMediaProvider(props) {
  const { changeHandler } = props
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <List>
        <ListItem>
          <IconButton aria-label="ws"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={ws_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            height="10px"
            required
            autoFocus
            id="ws"
            name="ws"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.ws}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="instagram"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={ig_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            required
            autoFocus
            id="fb"
            name="fb"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.fb}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="fb"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={fb_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            required
            autoFocus
            id="ig"
            name="ig"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.ig}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="page"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={page_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            required
            autoFocus
            id="www"
            name="www"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.www}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="email"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="email"
              src={email_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            required
            autoFocus
            id="email"
            name="email"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.email}
            onChange={changeHandler}
          />
        </ListItem>

      </List>
    </Box>
  )
}
export function TypeProvider(props) {
  const { changeHandlerChekbox } = props
  return (
    <div>
      <FormGroup>
        <FormControlLabel name="hotel" control={<Checkbox checked={props.tipoprestador.hotel} onChange={changeHandlerChekbox} />} label="Hotel y posada" />
        <FormControlLabel name="restaurant" control={<Checkbox checked={props.tipoprestador.restaurant} onChange={changeHandlerChekbox} />} label="Restaurant" />
        <FormControlLabel name="paseos" control={<Checkbox checked={props.tipoprestador.paseos} />} onChange={changeHandlerChekbox} label="Paseos" />
        <FormControlLabel name="operador" control={<Checkbox checked={props.tipoprestador.operador} />} onChange={changeHandlerChekbox} label="Operador turistico" />
        <FormControlLabel name="buses" control={<Checkbox checked={props.tipoprestador.buses} />} onChange={changeHandlerChekbox} label="Buses y embarcaciones" />
        <FormControlLabel name="toldos" control={<Checkbox checked={props.tipoprestador.toldos} />} onChange={changeHandlerChekbox} label="Toldos" />
        <FormControlLabel name="seguros" control={<Checkbox checked={props.tipoprestador.seguros} />} onChange={changeHandlerChekbox} label="Seguros de viaje" />
      </FormGroup>
    </div>
  )
}
export function ServicesProvider(props) {
  const { changeHandler } = props
  return (
    <div>
      <TextField
        required
        autoFocus
        id="servicio1"
        name="servicio1"
        label="Servicio 1"
        type="text"
        fullWidth
        inputProps={{ maxLength: 200 }}
        value={props.servicio1}
        onChange={changeHandler}
      />
      <TextField
        required
        id="servicio2"
        name="servicio2"
        label="Servicio 2"
        type="tel"
        fullWidth
        inputProps={{ maxLength: 200 }}
        value={props.servicio2}
        onChange={changeHandler}
      />
      <TextField
        id="servicio3"
        name="servicio3"
        label="Servicio 3"
        type="ciudad"
        fullWidth
        inputProps={{ maxLength: 200 }}
        value={props.servicio3}
        onChange={changeHandler}
      />
    </div>
  );
}
export default DataBasicProvider;