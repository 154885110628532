import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import Grid from '@mui/material/Grid';

import foto_prueba1 from '../../assets/images/foto_prueba-1.jpg';
import foto_prueba2 from '../../assets/images/foto_prueba-2.jpg';
import foto_prueba3 from '../../assets/images/foto_prueba-3.jpg';
import foto_prueba4 from '../../assets/images/foto_prueba-4.jpg';

import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import PersonPinCircleTwoToneIcon from '@mui/icons-material/PersonPinCircleTwoTone';

import FunctionsCommon from '../functions/FunctionsCommon';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper(props) {
  const [imagenes, setImagenes] = React.useState(props.imagenes);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.imagenes.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const useStyles = makeStyles(() => ({
    alertBar: {
      width: '100%',
      top: 151,
      backgroundColor: 'none',
      backdropFilter: 'blur(3px)',
    },
    dot: {
      backgroundColor: "#FFF"
    },
    dotActive: {
      backgroundColor: "#3f51b5"
    }
  }));

  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1, height: 200, maxWidth: 340 }}>
      {/* <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.white',
        }}
      >
        <Typography variant="h5" color="text.secondary">{imagenes.imagenes[activeStep].etiqueta}</Typography>
      </Paper> */}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoplay={false}
        sx={{ borderRadius: '0px 24px 0px 0px' }}
      >
        {imagenes.map((step, index) => (
          <div key={step.nombre_imagen}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Grid sx={{ borderRadius: '0px 24px 0px 0px' }}>
                {/* <Box
                  component="img"
                  sx={{
                    height: 200,
                    display: 'inline',
                    maxWidth: 400,
                    overflow: 'hidden',
                    width: '15%',                    
                  }}
                /> */}
                <Box
                  id="box_size"
                  component="img"
                  sx={{
                    height: 200,
                    display: 'inline',
                    overflow: 'hidden',
                    width: '100%',                    
                    borderRadius: {
                      xs: '0px 0px 0px 0px',
                      sm: '0px 10px 0px 0px',
                      md: '0px 10px 0px 0px',
                      lg: '0px 10px 0px 0px',
                      xl: '0px 10px 0px 0px',
                    }
                  }}
                  src={`${FunctionsCommon.obtenerImagenDesdeItem(props.codigo_prestador, step.nombre_imagen, 'fotos')}`}
                  alt={step.comentario}
                />
                <Alert
                  sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: 25,
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    color: 'white',
                    fontWeight: 'bold',
                    padding: '0px 0px 0px 6px',
                    borderRadius: {
                      xs: '0px 0px 0px 0px',
                      sm: '0px 10px 0px 0px',
                      md: '0px 10px 0px 0px',
                      lg: '0px 10px 0px 0px',
                      xl: '0px 10px 0px 0px',
                    }
                  }}
                  iconMapping={{
                    success: <PersonPinCircleTwoToneIcon fontSize="inherit" />,
                  }}
                  severity="success">
                  {step.comentario}
                </Alert>
                {/* <Box
                  component="img"
                  sx={{
                    height: 200,
                    display: 'inline',
                    maxWidth: 400,
                    overflow: 'hidden',
                    width: '15%',
                  }}
                /> */}
              </Grid>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps > 10 ? 10 : maxSteps}
        position="static"
        activeStep={activeStep}        
        classes={{
          dot: classes.dot,
          dotActive: classes.dotActive,
        }}
        sx={{
          bgcolor: 'transparent',
          backgroundColor: 'rgba(0,0,0,0.3)',
          width: '100%',
          position: 'relative',
          height: 25,
          top: -31,
          borderRadius: '0px 10px 0px 0px'
        }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            sx={{ color: 'white', fontWeight: 'bold' }}
          >
            Siguiente
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button sx={{ color: 'white', fontWeight: 'bold' }} size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Anterior
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;
